<template>
  <div :class="'lds-ring-' + size">
    <div :style="ringStyles"></div>
    <div :style="ringStyles"></div>
    <div :style="ringStyles"></div>
    <div :style="ringStyles"></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 2
    },
    hexColor: {
      type: String,
      default: '8B2E2C'
    }
  },
  computed: {
    ringStyles() {
      return {
        'border-color': `#${this.hexColor} transparent transparent transparent`
      }
    }
  },
}
</script>

<style scoped lang="scss">
$size-values: (2, 3, 4, 5, 6, 7, 8); // Valores de size disponibles

@each $size in $size-values {
  .lds-ring-#{$size} {
    display: inline-block;
    position: relative;
    width: $size * 10px;
    height: $size * 10px;
  }
  .lds-ring-#{$size} div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: $size * 8px;
    height: $size * 8px;
    margin: $size * 1px;
    border-width: $size * 1px;
    border-width: 2px;
    border-style: solid;
    /* border-color: #8B2E2C; */
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    /* border-color: #8B2E2C transparent transparent transparent; */
  }
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>