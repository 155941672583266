<template>
    <ButtonDefinitive
      variant='ghost'
      label="Continuar con Google"
      @click="handleGoogleLogin"
    >
      <template v-slot:icon>
        <img src="~@/assets/img/icon/icon-google_v2.svg">
      </template>
    </ButtonDefinitive>
</template>

<script>

import ButtonDefinitive from "../Button/ButtonDefinitive.vue"

export default {
  name: "GoogleSignInButton",
  components: {
    ButtonDefinitive
  },
  props: {
    queryParams: {
      type: String,
      default: ''
    },
    isRegistrationPage: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.loadGoogleSignIn();
  },
  methods: {
    getQueryParameters () {
      const currentPathWithQuery = this.queryParams
      const queryIndex = currentPathWithQuery.indexOf('?')
      return queryIndex !== -1 ? currentPathWithQuery.slice(queryIndex + 1) : ''
    },
    loadGoogleSignIn() {
      window.google.accounts.id.initialize({
        client_id:`${process.env.VUE_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`,
        ux_mode: "popup",
        callback: this.handleGoogleResponse,
      });
      this.createFakeGoogleWrapper();
    },
    createFakeGoogleWrapper() {
      const googleLoginWrapper = document.createElement("div");
      googleLoginWrapper.style.display = "none";

      document.body.appendChild(googleLoginWrapper);

      window.google.accounts.id.renderButton(googleLoginWrapper, {
        type: "icon",
        width: "200",
      });

      const googleLoginWrapperButton =
        googleLoginWrapper.querySelector("div[role=button]");
      this.googleButtonWrapper = {
        click: () => {
          googleLoginWrapperButton.click();
        },
      };
    },
    handleGoogleLogin() {
      this.trackEvent('click_google_btn', 'login')
      this.googleButtonWrapper.click();
    },
    handleGoogleResponse(response) {
      const $this = this;
      $this.showLoading();
        const { credential } = response;
        const queryString = this.getQueryParameters()
        $this.$store.dispatch("GOOGLE_LOGIN", {accessToken: credential, queryString})
          .then((success) => {
            if (success.status === 200) {
              this.trackEvent('logged_with_google', 'login')

              const result = success.data.result;
              $this.$store.dispatch("USER_SET_USER", result);

              if(result.isNewClient){
                this.trackEvent('login_with_google_after_register_success', 'login')
                if (this.isRegistrationPage) {
                  fbq('track', 'CompleteRegistration')
                }
              } else {
                this.trackEvent('login_with_google_success', 'login')
              }
              
              window.location.href = process.env.VUE_APP_WEBURL;
            } else {
              $this.showError(success.data.message);
              this.trackEvent('login_with_google_failed', 'login')
            }
          })
          .catch((error) => {
            const msg = error?.data?.message || "Ocurrio un error.";
            $this.showError(msg);
            this.trackEvent('login_with_google_failed', 'login')
          });
      return false;
    }
  },
};
</script>

<style>

</style>
