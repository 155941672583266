<!-- src/components/AppleSignInButton.vue -->
<template>
  <ButtonDefinitive
    variant='ghost'
    label="Continuar con Apple"
    @click="handleAppleLogin"
  >
    <template v-slot:icon>
      <img src="~@/assets/img/icon/icon-apple.svg">
    </template>
  </ButtonDefinitive>
</template>

<script>
import ButtonDefinitive from "../Button/ButtonDefinitive.vue"

export default {
  name: "AppleSignInButton",
  components: {
    ButtonDefinitive
  },
  props: {
    queryParams: {
      type: String,
      default: ''
    },
    isRegistrationPage: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.loadAppleSignIn();
  },
  methods: {
    loadAppleSignIn() {
      const script = document.createElement('script');
      script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      script.onload = this.initializeAppleSignIn;
      document.head.appendChild(script);
    },
    initializeAppleSignIn() {
      AppleID.auth.init({
        clientId: `${process.env.VUE_APP_APPLE_CLIENT_ID}`,
        scope: "name email",
        redirectURI: `${process.env.VUE_APP_WEBURL}`,
        state: "sharf",
        usePopup: true,
      });

      document.addEventListener("AppleIDSignInOnSuccess", this.handleAppleResponse);
      document.addEventListener("AppleIDSignInOnFailure", this.handleAppleFailure);
    },
    handleAppleLogin() {
      AppleID.auth.signIn();
    },
    handleAppleResponse(event) {
      const $this = this;
      const { authorization, user } = event.detail;

      const idToken = authorization.id_token;

      const payload = { idToken };

      if (user) {
          payload.user = {
              firstName: user.name.firstName,
              lastName: user.name.lastName
          };
      }

      $this.$store.dispatch("APPLE_LOGIN", { payload })
        .then((success) => {
          if (success.status === 200) {
            this.trackEvent('logged_with_apple', 'login')

            const result = success.data.result;
            $this.$store.dispatch("USER_SET_USER", result);

            if(result.isNewClient){
              this.trackEvent('login_with_apple_after_register_success', 'login')
              if (this.isRegistrationPage) {
                fbq('track', 'CompleteRegistration')
              }
            } else {
                this.trackEvent('login_with_apple_success', 'login')
            }
            
            window.location.href = process.env.VUE_APP_WEBURL;
          } else {
            $this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrió un error.";
          $this.showError(msg);
          this.trackEvent('login_with_apple_failed', 'login')
        });
      return false;
    },
    handleAppleFailure(event) {
      console.error("Sign-in failed");
      console.error(event.detail.error);
      // Manejar el error de inicio de sesión
    }
  }
};
</script>

<style scoped>

</style>
