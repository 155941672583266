<template>
  <form :id="formId" style='width: 100%' @submit.prevent='handleSubmit'>
    <div class="login-form">
      <InputText
        email
        label="Correo electrónico"
        autocomplete="email"
        placeholder="Ingresa tu correo electrónico."
        v-model="email"
        missingErrorMessage="Ingresa tu correo electrónico."
        :showEmptyInputError="tryToSubmit && !email"
      />
      <InputText
        password
        label="Contraseña"
        placeholder="Ingresa tu contraseña"
        v-model="password"
        missingErrorMessage="Ingresa tu contraseña."
        :showEmptyInputError="tryToSubmit && !password"
      />
      <p
        class='text-m-bold'
        style='text-decoration: underline; cursor: pointer;'
        @click='redirectForgotPassword'
      >
        ¿Olvidaste tu contraseña?
      </p>
    </div>
    <div style='padding-top: 32px'>
      <ButtonDefinitive
        label="Iniciar sesión"
        buttonType='submit'
      />
    </div>
  </form>
</template>

<script>
import { api } from "../../services/api";
import InputText from '../Form/InputText.vue'
import ButtonDefinitive from "../Button/ButtonDefinitive.vue"
import utils from '../../commons/mixins/utils'

export default {
  name: "LoginForm",
  components: {
    InputText,
    ButtonDefinitive,
  },
  props: {
    formId: String,
  },
  data() {
    return {
      email: "",
      password: "",
      tryToSubmit: false
    };
  },
  methods: {
    redirectForgotPassword() {
      this.$router.push('/forgotPassword')
    },
    handleSubmit() {
      this.trackEvent('click_login_with_email_btn', 'login')
      this.tryToSubmit = true
      if (!this.email || !this.password) {
        this.trackEvent('click_login_with_email_failed_empty_inputs', 'login') 
        return
      }

      if (!utils.methods.validateEmail(this.email)){
        this.trackEvent('click_login_with_email_failed_invalid_email', 'login') 
        return
      } 
      this.processLogin()
    },
    processLogin() {
      this.showLoading()
      const payload = {
        email: this.email,
        password: this.password,
      }
      api.post(`auth/clients`, payload)
        .then(response => {
          console.log(response)
          if (response.status !== 200) throw new Error()

          this.$store.commit('SET_USER', response.data.result)
          this.$swal.close()

          this.trackEvent('login_with_email_success', 'login')

          window.location.href = process.env.VUE_APP_WEBURL
        })
        .catch(e => {
          console.log({ e })
          this.showError('Revise sus datos e intente nuevamente.')
          this.trackEvent('login_with_email_failed', 'login')
        })
    }
  },
};
</script>
<style lang="scss" scoped>
.login-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}
</style>